import 'moment/locale/es';
import moment from 'moment';
import i18n, { ReactOptions, InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import tranlations from './config/translations';

const reactOptions: ReactOptions = {
    bindI18n: 'languageChanged loaded',
    nsMode: 'default',
}

const initOptions: InitOptions ={
    fallbackLng: 'en-US',
    ns: ['pardockTranslation'],
    defaultNS: 'pardockTranslation',
    debug: true,
    react: reactOptions,
    resources: tranlations,
}

const i18nInit = () => {
    const i18nInstance = (
        i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init(initOptions)
    );
    
    // i18n.changeLanguage('en-US');
    moment.locale('en');
    return i18nInstance;
}

export default i18nInit;