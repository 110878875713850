import React, { Fragment, useEffect, useState } from 'react'
import animateScrollTo from 'animated-scroll-to';
import NavBar from './NavBar'
import Header from './Header'
import About from './About'
import Lines from './Lines';
import Footer from './Footer';
import Skills from './Skills';
import Services from './Services';
import Counter from './Counter';
import Reviews from './Reviews';
import Contact from './Contact';

const Main = () => {
    const [ show, setShow ] = useState('fadeOut');

    const scrollTop = () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > 100){
            setShow('fadeIn')
        } else {
            setShow('fadeOut')
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollTop)
      },[]);

      const toTheTop = (e: React.MouseEvent) => {
        e.preventDefault();
        animateScrollTo(0, { speed: 1500})
      }

    return (
        <Fragment>
            <div className="maslin-page-wrapper">
                <NavBar />
                <Header />
                <About />
                <Skills />
                <Services />
                <Counter />
                <Reviews />
                <Contact />
                 <Lines />
            </div>
            <Footer />
            <a href="!#" onClick={(e: React.MouseEvent) => toTheTop(e)} className={`back-to-top back-to-top-pulse ${show}`}><i className="fa fa-caret-up"></i></a>
        </Fragment>
    );
}
 
export default Main;