const en = {
    pardockTranslation:{
        home: {
            title :'Welcome 💻 - Pardock 🤓'
        },
        navbar: {
            home: 'Home',
            about: 'About',
            service: 'Service',
            project: 'Project',
            review: 'Review',
            contact: 'Contact',
            title: 'José Pardo',
        },
        heading:{
            title: 'JOSÉ PARDO',
            text: `I'm a full stack developer and tech leader with a passion for learning.`,
            help: 'I help your business and individuals by developing digital platforms. I build websites and platforms to make you successful in the long term.',
            typed: {
                fullstack: 'Work with a Professional full stack developer.',
                techleads: 'Meet a Professional tech lead.',
                enginniers: 'Build with a Professional enginnier.',
                person: 'Talk wit a Excelent person.',
            },
            touch: 'Get in Touch',
            social: 'Social Links',
        },
        about:{
            title: 'About me',
            welcome: `Welcome to <span class="color-text">My Profile</span>`,
            init: `- It's my pleasure to introduce about myself.`,
            text: `I'm <strong class="color-text">José Domínguez Pardo</strong>. I'm a fullstack developer and tech lead in digital platforms, with 5+ years of experience development, planning and executing technological projects. I have vast knowledge of the most popular web technologies front-end, back-end and Cloud.  I have deep understanding of the technologies and the processes required for realising a successful web project from A to Z.`,
            values :`What are my values?`,
            valuesText: 'When developing a web project, I focus on understanding the business goals and delivering a final product that will really work for the client.',
            cv: 'Download my CV',
        },
        services: {
            title: 'My Service',
            items:{
                web: 'Web Development',
                agile: 'Agile Leadership',
                consult: 'Technology Consultant',
                data: 'Data Analysis',
                soft: 'Soft Skills',
                team: 'Team Builder',
            },
            description: {
                web: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore.',
                market: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore.',
                ux :'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore.',
                communication: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore.',
            }
        },
        counter: {
            years: 'Years of experience',
            complete: 'Complete Project',
            happy: 'Happy Client',
            awards: 'Award Winner'
        },
        reviews: {
            title: 'Reviews of Experts',
            list: {
                pili : `A professional very committed to his work and his ideals. It has the ability to learn quickly and adapt easily to changes or new technologies that are required.`,
                cesar: 'Professional who perfectly understood our requirements, was able to give us options and bring the project to a successful conclusion, respecting times, a gentle and very professional attitude.',
                renata: 'Excellent professional, very proactive in his work, always giving that extra in what is required.',
                adriana: 'José is an exceptional full stack, his customer service throughout the process is magnificent, we always receive his support, from the analysis of our needs to the delivery of our product. I highly recommend it; He is a very young boy for the experience he has and his ability to solve problems.',
                brac: 'Ing. Pardo is an excellent professional, dynamic and responsible, generates empathy with the work areas, clearly visualizes the need for service and urgently picks up customer expectations.'
            }
        },
        contact: {
            title: 'Contact Me',
            visitMe: 'Follow me',
            emailMe: 'Email me',
            type: {
                name: 'Your Name',
                email: 'Your Email',
                subject: 'Your Subject',
                message: 'Your Message',
            },
            btnSend: 'Send Message',
        },
        footer: {
            copy: `Copyright &copy; ${new Date().getFullYear()} - All Right Reserved.`,
            created: `Created by <a href="https://pardock.dev">José Domìnguez Pardo</a>`,
            language: 'Spanish Version',
        },
        maps:{
            text: 'Hello World 😝!'
        },
        formValidation: {
            inputs: 'All fields are required !',
            email: `The email it's not format valid !`,
            correct: 'Yei ! I recibed you request information !',
            sendError: 'Ups, be have a problema send the email, try again.',
            token: 'Please tell us, if you are a human !'
        }
    }
}

export default en;