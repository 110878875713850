import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import SkillItem from './SkillItem';
import { sleep } from '../utils'
import Skills from '../config/interfaces/Skills';

const listSkills: Skills[] = [
    {
        title: 'Node JS',
        value: 90,
        key: 1,
        width: 0,
    },
    {
        title: 'React JS',
        value: 85,
        key: 3,
        width: 0,
    },
    {
        title: 'SQL',
        value: 95,
        key: 4,
        width: 0,
    },
    {
        title: '.Net Core',
        value: 80,
        key: 5,
        width: 0,
    },
    {
        title: 'Amazon Web Service',
        value: 90,
        key: 6,
        width: 0,
    },
    {
        title: 'Team Leader',
        value: 95,
        key: 2,
        width: 0,
    },
]

const SkillsComponent = () => {
    const [ skills, setSkills] = useState(listSkills);

    const handleProgressBar = ()=> {
        sleep(1000).then(() => {
            let tmpSkill = [...skills];
            for(let it of tmpSkill){
                it.width = it.value;
            }
            setSkills(tmpSkill);
        });
    }
    return ( 
        <section className="skill-area section-padding">
			<div className="container">
                <Waypoint onEnter={handleProgressBar}>
                    <div className="row">
                        {
                            skills.map( (s,i) => (
                                <SkillItem key={i} skill={s} />
                            ))
                        }
                    </div>
                </Waypoint>
			</div>
		</section>
     );
}
 
export default SkillsComponent;
