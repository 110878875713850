import React, { useState, useEffect } from 'react'

const PreLoader = ({ callback }) => {
    const [ loader, setLoader] = useState('');
    const [ loaderMid, setLoaderMid] = useState('');    

    useEffect(() => {
        const loaderRemove = () => {
            setTimeout(function () {
                setLoaderMid('loaded')
                setTimeout(function () {
                    setLoader('loaded')
                    setTimeout(function () {
                        callback(false);
                    }, 600);
                }, 600);
            }, 1000);
        }

        loaderRemove();
    },[callback]);
    return ( 
        <div id="preloader-wapper" className={loader}>
            <div className={`loader-middle ${loaderMid}`}></div>
        </div>
     );
}
 
export default PreLoader;