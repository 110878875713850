import React, { useEffect, useRef } from 'react';
import animateScrollTo from 'animated-scroll-to';
import { withTranslation } from 'react-i18next';
import Typed from 'typed.js';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import TranslationProps from '../config/interfaces/TranslationProps';
import { Engine, ISourceOptions } from 'tsparticles-engine';

const Header: React.FC<TranslationProps> = ({ t }: TranslationProps) => {
    const el = useRef(null);

    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: [t('heading.typed.fullstack'), t('heading.typed.techleads'), t('heading.typed.enginniers'), t('heading.typed.person')],
            startDelay: 0,
            typeSpeed: 50,
            backSpeed: 0,
            backDelay: 1000
        });

        // Destropying
        return () => {
            typed.destroy();
        };
    }, [t]);

    const particlesInit = async (main: Engine) => {
        await loadFull(main);
    };

    const params: ISourceOptions = {
        fullScreen: false,
        particles: {
            number: {
                value: 80,
                density: {
                    enable: true,
                    value_area: 800,
                },
            },
            color: {
                value: "#ddd",
            },
            shape: {
                type: "circle",
                stroke: {
                    width: 0,
                    color: "#888888",
                },
                polygon: {
                    nb_sides: 5,
                },
                image: {
                    src: "img/github.svg",
                    width: 100,
                    height: 100,
                },
            },
            opacity: {
                value: 0.5,
                random: false,
                anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                },
            },
            size: {
                value: 5,
                random: true,
                anim: {
                    enable: false,
                    speed: 40,
                    size_min: 0.1,
                    sync: false,
                },
            },
            line_linked: {
                enable: true,
                distance: 150,
                color: "#777",
                opacity: 0.4,
                width: 1,
            },
            move: {
                enable: true,
                speed: 6,
                direction: "none",
                random: false,
                straight: false,
                out_mode: "out",
                attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                },
            },
        },
        interactivity: {
            detect_on: "canvas",
            events: {
                onhover: {
                    enable: true,
                    mode: "repulse",
                },
                onclick: {
                    enable: true,
                    mode: "push",
                },
                resize: true,
            },
            modes: {
                grab: {
                    distance: 400,
                    line_linked: {
                        opacity: 1,
                    },
                },
                bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                },
                repulse: {
                    distance: 200,
                },
                push: {
                    particles_nb: 4,
                },
                remove: {
                    particles_nb: 2,
                },
            },
        },
        retina_detect: true,
        background: {
            color: "#dde4e6",
        }
    }
    const contactMe = (e: React.MouseEvent) => {
        e.preventDefault();
        const element = document.getElementById('contact')
        if (element !== null)
            animateScrollTo(element, { speed: 1000 });
    }
    return (
        <header id="home" className="home-area hero-equal-height overflow-hidden section-padding">
            <Particles options={params} init={particlesInit} id="particles-js" />
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2">
                        <div className="text-center home-content z-index position-relative">
                            <h1>{t('heading.title')}</h1>
                            <p>{t('heading.text')}</p>
                            <p> {t('heading.help')}
                                {' '}
                                <span ref={el}></span>
                            </p>
                            <a href="!#" onClick={(e) => contactMe(e)} className="button js-scroll">{t('heading.touch')}</a>
                        </div>

                    </div>

                    <div className="social">
                        <a className="text" href="!#">{t('heading.social')}</a>
                        <div className="line"></div>
                        <a href="https://twitter.com/jpardock" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter" aria-hidden="true"></i>  </a>
                        <a href="https://instagram.com/pardock" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" aria-hidden="true"></i>  </a>
                        <a href="https://www.linkedin.com/in/pardock" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin" aria-hidden="true"></i>  </a>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default withTranslation()(Header);
