const es = {
    pardockTranslation:{
        home: {
            title :'Bienvenido 💻 - Pardock 🤓'
        },
        navbar: {
            home: 'Inicio',
            about: 'Acerca de',
            service: 'Servicios',
            project: 'Proyectos',
            review: 'Reseñas',
            contact: 'Contacto',
            title: 'José Pardo',
        },
        heading:{
            title: 'JOSÉ PARDO',
            text: `Yo soy un desarrollador full stack y líder técnico con pasión por el aprendizaje.`,
            help: 'Ayudo a los negocios y personas, desarrollando plataformas digitales. Construyo aplicaciones web y plataformas para que tengas el éxito en un largo plazo. ',
            typed: {
                fullstack: 'Trabaja con un desarrollador full stack.',
                techleads: 'Conoce a un profesional líder técnico.',
                enginniers: 'Crea con un profesional ingeniero.',
                person: 'Platica con una excelente persona.',
            },
            touch: 'Contactar',
            social: 'Redes Sociales',
        },
        about:{
            title: 'Acerca de mi',
            welcome: `Bienvenido a <span class="color-text">Mi Perfil</span>`,
            init: `- Es un placer darte una introducción sobre mí.`,
            text: `Yo soy <strong class="color-text">José Domínguez Pardo</strong>. Soy un desarrollador fullstack y líder técnico en plataformas digitales, con más de 5 años de experiencia construyendo, planificando y ejecutando proyectos tecnológico. Cuento con basto conocimiento de las últimas tecnologías Front-end, Back-end y Cloud. De la misma manera cuento con un profundo conocimiento de todo el proceso de desarrollo web de una manera exitosa de la A la Z.`,
            values :`¿Cuáles son mis valores?`,
            valuesText: 'Al desarrollar un proyecto web, me concentro en entender los objetivos del negocio y entregar un producto final que realmente sea útil para el cliente',
            cv: 'Descarga mi CV',
        },
        services: {
            title: 'Mi Servicio',
            items: {
                web: 'Desarrollador Web',
                agile: 'Liderazgo Agíl',
                consult: 'Consultor Tecnologico',
                data: 'Analista de Datos',
                soft: 'Habilidades Interpersonales',
                team: 'Creador de Equipos',
            },
            description: {
                web: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore.',
                market: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore.',
                ux :'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore.',
                communication: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore.',
            }
        },
        counter: {
            years: 'Años de experiencia',
            complete: 'Proyectos concluidos',
            happy: 'Clientes satisfechos',
            awards: 'Logros'
        },
        reviews: {
            title: 'Reseñas de Expertos',
            list: {
                pili : `Un profesional muy comprometido con su trabajo y sus ideales. Tiene la capacidad de aprender rápido y adaptarse fácilmente a los cambios o nuevas tecnologías que se requieran.`,
                cesar: 'Profesional que entendió perfectamente nuestros requerimientos , pudo darnos opciones y llevar el proyecto a buen término respetando tiempos , actitud gentil y muy profesional.',
                renata: 'Excelente profesionista, muy proactivo en sus labores, siempre dando ese extra en lo que se le requiere.',
                adriana: 'José es un full stack excepcional, su atención al cliente en todo el proceso es magnifico, siempre recibimos su acompañamiento, desde el análisis de nuestras necesidades hasta la entrega de nuestro producto. Lo recomiendo ampliamiente; es un chico muy joven para la experiencia que tiene y su capacidad de resolver problemas.',
                brac: 'El Ing. Pardo es un excelente profesional, dinámico y responsable, genera empatía con las áreas de trabajo, visualiza con claridad la necesidad de servicio y repunta con sentido de urgencia la expectativa del cliente.'
            }
        },
        contact: {
            title: 'Contactame',
            visitMe: 'Sigueme',
            emailMe: 'Enviame un correo',
            type: {
                name: 'Tu Nombre',
                email: 'Tu Correo',
                subject: 'Tu Asunto',
                message: 'Tu Mensaje',
            },
            btnSend: 'Enviar Mensaje',
        },
        footer: {
            copy: `Copyright &copy; ${new Date().getFullYear()} - Todos los Derechos Reservados.`,
            created: `Creado por <a href="https://pardock.dev">José Domìnguez Pardo</a>`,
            language: 'Versiòn en Ingles',
        },
        maps:{
            text: '! Hola Mundo 😝!'
        },
        formValidation: {
            inputs: 'Todos los campos son requeridos !',
            email: `El correo tiene un formato invalido !`,
            correct: 'Si ! Ya recibi tu solicitud !',
            sendError: 'Ups, Se ha tenido un problema al enviar el correo, intenta nuevamente.',
            token: 'Por favor dinos que eres humano !',
        }
    }
}

export default es;