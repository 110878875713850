import React, { useState } from 'react'
import { withTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import Axios from 'axios';
import TranslationProps from '../config/interfaces/TranslationProps';

const Contact: React.FC<TranslationProps> = ({ t }: TranslationProps) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [captcha, setCaptcha] = useState('')
    const [error,setError] = useState('');
    const [errorStyle,setErrorStyle] = useState('');


    const actionRequest = (e: React.SyntheticEvent) => {
        setError('');
        setErrorStyle('');

        if(name === '' || email === '' || subject === '' || message === ''){
            setError(t('formValidation.inputs') || '');
            setErrorStyle('error');
            return;
        }

        if(!validateEmail(email)){
            setError(t('formValidation.email') || '');
            setErrorStyle('error');
            return;
        }

        if(captcha === ''){
            setError(t('formValidation.token') || '');
            setErrorStyle('error');
            return;
        }

        const headers ={
            'Content-type': 'application/json',
            'Authorization': '75155D7B3EECD04A6E905F310CD82980E149C44EF1AF2446A7CE146C41730F63',
            'x-token-key': `${captcha}`
        }
        const payload = {
            to: email,
            subject,
            message,
            name,
        }

        const url = 'https://us-central1-pardock-personal.cloudfunctions.net/v1/send';
        Axios.post(url,payload,{ headers }).then(result => {
            setError(t('formValidation.correct') || '');
            setErrorStyle('success');
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
        }).catch(err => {
            setError(t('formValidation.sendError') || '');
            setErrorStyle('error');
        })


    }

    const validateEmail = (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    return ( 
        <section id="contact" className="contact-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 section-title">
                        <h2>{ t('contact.title') }</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-5">
                        <div className="row contact-information">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="contact-details">
                                    <i className="fa fa-linkedin"></i>
                                    <h6>{ t('contact.visitMe') }</h6>
                                    <p>
                                    <a href="https://www.linkedin.com/in/pardock" target="_blank" rel="noopener noreferrer">LinkedIn@Pardock</a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="contact-details">
                                    <i className="fa fa-envelope"></i>
                                    <h6>{ t('contact.emailMe') }</h6>
                                    <p>
                                        <a href="mailto:me@pardock.dev">me@pardock.dev</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                        <p className={`form-message ${errorStyle}`}>{error}</p>
                        <br />
                        <form className="contact-form form" id="contact-form" onSubmit={actionRequest}>
                            <div className="controls">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group has-error has-danger">
                                            <input onChange={(e) => setName(e.target.value)} value={name} id="form_name" type="text" name="name" placeholder={t('contact.type.name') || ''} required={true} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group has-error has-danger">
                                            <input onChange={(e) => setEmail(e.target.value)} value={email} id="form_email" type="email" name="email" placeholder={ t('contact.type.email') || '' } required={true} /> </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group has-error has-danger">
                                            <input onChange={(e) => setSubject(e.target.value)} value={subject} id="form_subject" type="text" name="subject" placeholder={ t('contact.type.subject') || '' } required={true} /> </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea onChange={(e) => setMessage(e.target.value)} value={message} id="form_message" name="message" placeholder={ t('contact.type.message') || '' } rows={4} required={true}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                        <ReCAPTCHA sitekey="6LeCd8YZAAAAAFIjL6XKjtKx0Z_RG4DSr1VZOj_z" onChange={value => setCaptcha(value || '')} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <button type="submit" className="button" data-text={ t('contact.btnSend') }><span>{ t('contact.btnSend') }</span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default withTranslation()(Contact);