import React,{ useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next';
import animateScrollTo from 'animated-scroll-to';
import LinkItems from '../config/interfaces/LinkItems';
import TranslationProps from '../config/interfaces/TranslationProps';

const NavBar: React.FC<TranslationProps> = ({ t }: TranslationProps) => {
    const [ navBarStyle, setNavBarStyle] = useState('navbar-trans');
    const [ collapseStyle, setCollapse ] = useState('collapsed');
    const [ show, setShow ] = useState('collapse');
    const [ flagCollapse, setFlagCollapse ] = useState(false);
    const navExpand = () => {
        const scrollTop = window.pageYOffset;
        const pixels = 50;
        
        if (scrollTop > pixels) {
          setNavBarStyle('navbar-reduce');
        } else {
          setNavBarStyle('navbar-trans');
        }

      }

      const collapseNav = () => {
        setNavBarStyle('navbar-reduce')
        setShow('collapsing')
        if(flagCollapse){
            setFlagCollapse(false);
            setCollapse('collapsed')
            setTimeout(()=> {
                setShow('collapse')
            },500)
        }else{
            setCollapse('')
            setFlagCollapse(true);
            setTimeout(()=> {
                setShow('collapse show')
            },500)
        }
      }

      const getElement = (id: string): (HTMLElement|null) => {
        return document.getElementById(id);
    }

      const clickLink = (e: React.MouseEvent, item: LinkItems) => {
        e.preventDefault();
        setShow('collapse');
        setCollapse('collapsed');
        setFlagCollapse(false);
        const element = getElement(`${item.route}`)
        if(element !== null) {
            animateScrollTo( element, { speed : 1000 });
        }
      }
    
      useEffect(() => {
        window.addEventListener('scroll', navExpand)
      },[]);

      const linkItems: LinkItems[] = [
        {
            title: 'navbar.home',
            route: 'home',
        },
        {
            title: 'navbar.about',
            route: 'about',
        },
        {
            title: 'navbar.service',
            route: 'service',
        },
        {
            title: 'navbar.review',
            route: 'review',
        },
        {
            title: 'navbar.contact',
            route: 'contact',
        },
      ];

    return ( 
        <nav className={`navbar navbar-b ${navBarStyle} navbar-expand-md fixed-top`} id="mainNav">
            <div className="container">
                <a className="navbar-brand js-scroll" href="/">{t('navbar.title')}</a>
                <button className={`navbar-toggler ${collapseStyle}`} onClick={collapseNav} type="button" data-toggle="collapse" data-target="/navbarDefault" aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className={`navbar-collapse ${show} justify-content-end`} id="navbarDefault">
                    <ul className="navbar-nav">
                        {
                            linkItems.map( (l, i) => (
                                <li className="nav-item" key={i}> 
                                    <a className="nav-link js-scroll" href="!#" onClick={(e: React.MouseEvent ) => clickLink(e,l)}>{t(l.title)}</a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </nav>
     );
}
 
export default withTranslation()(NavBar);