import React, { useState } from 'react';
import OwlCarousel, { Options } from 'react-owl-carousel';
import { withTranslation } from 'react-i18next';
import TranslationProps from '../config/interfaces/TranslationProps';

const Reviews: React.FC<TranslationProps> = ({ t }: TranslationProps) => {
	const [review] = useState([
    {
      name: "Pilar Solorzano",
      job: "Java Web Developer",
      url: "https://www.linkedin.com/in/pilar-sol%C3%B3rzano-villanueva-013638183/",
      img: "/assets/img/reviews/pili.jpg",
      styleCustom: { },
      text: "reviews.list.pili",
    },
    {
      name: "Cesar Vazquez",
      job: "CEO & Co-Founder - Harbinter",
      url: "https://harbinter.com/",
      img: "/assets/img/reviews/harbinter.png",
	  styleCustom: { backgroundColor: "#3498db" },
	  text: "reviews.list.cesar",
    },
    {
      name: "Renata Padilla",
      job: "Data Strategist - Beker/Socialand",
      url: "https://www.linkedin.com/in/renata-p-8a421b38/",
      img: "/assets/img/reviews/renata.jpg",
      styleCustom: {},
      text: "reviews.list.renata",
    },
    {
      name: "Adriana Ovando",
      job: "Chief Public Relationship",
      url: "https://www.linkedin.com/in/adrianaovando/",
      img: "/assets/img/reviews/adriana.jpeg",
      styleCustom: {  },
      text: "reviews.list.adriana",
	},
	{
	  name: "Christian Bonola",
	  job: "CEO BRAC Firma Legal",
	  url: "https://www.bracfirmalegal.com",
	  img: "/assets/img/reviews/brac.jpeg",
	  styleCustom: {  },
	  text: "reviews.list.brac",
	  },
  ]);
	const options: Options = {
		margin: 5,
		autoplay: true,
		center: true,
		autoplayTimeout: 4000,
		nav: false,
		smartSpeed: 1000,
		dots: false,
		autoplayHoverPause: true,
		loop: true,
        responsiveClass:'true',
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 2
			},
			1000: {
				items: 3
			}
		}
	}
    return ( 
        <section id="review" className="testimonial-area section-padding">
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<div className="section-title">
							<h2>{ t('reviews.title') }</h2>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12 col-md-12">
						<div id="testimonial-slide" className="owl-carousel owl-theme owl-loaded owl-drag">
							{/* <!-- testimonials item --> */}
							<OwlCarousel {...options} >
								{ review.map( (r, i) => (
									<div className="single-testimonial" key={i}>
										<div className="testi-content-inner">
											<div className="avatar">
												<a href={r.url} target="_blank" rel="noopener noreferrer">
													<img src={r.img} alt={r.name} style={r.styleCustom} />
												</a>
											</div>
											<div className="testimonial-content">
												<h3>"{t(r.text)}"</h3>
											</div>
											<div className="testimonial-bio">
												<div className="bio-info">
													<h3 className="name">{r.name}</h3><span>{r.job}</span>
												</div>
											</div>
										</div>
									</div>
								))}
							</OwlCarousel>
						</div>
					</div>
				</div>
			</div>
		</section>
     );
}
 
export default withTranslation()(Reviews);