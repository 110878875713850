import React from 'react';
import { withTranslation } from 'react-i18next';
import TranslationProps from '../config/interfaces/TranslationProps';

const About: React.FC<TranslationProps> = ({ t }: TranslationProps) => {
    return ( 
        <section id="about" className="about-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="section-title">
                            <h2>{ t('about.title') }</h2>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12">
                        <div className="about-image">
                            <img src="assets/img/me.jpg" alt="img" />
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="about-content">
                            <h3 dangerouslySetInnerHTML={{ __html : t('about.welcome') || ''}}></h3>
                            <h3>{ t('about.init') }</h3>
                            <p dangerouslySetInnerHTML={{ __html: t('about.text') || ''}}></p>
                            <br />
                            <h3>{ t('about.values') }</h3>
                            <p>{ t('about.valuesText')}</p>
                            <br />
                            <p>
                                <br /><b>E-mail:</b> <a href="mailto:me@pardock.dev">me@pardock.dev</a>
                                <br /><b>Skype:</b> pardockdj</p>
                            {/* <a href="#" className="button js-scroll">{ t('about.cv') }</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default withTranslation()(About);