import React,{ useState } from 'react';
import { withTranslation } from 'react-i18next';
import Odometer from 'react-odometerjs';
import { Waypoint } from 'react-waypoint';
import { sleep } from '../utils'
import TranslationProps from '../config/interfaces/TranslationProps';

const Counter: React.FC<TranslationProps> = ({ t }: TranslationProps) => {
    const [ value, setValue] = useState([
        {
            name: 'counter.years',
            init: 0,
            value: 5,
        },
        {
            name: 'counter.complete',
            init: 0,
            value: 13,
        },
        {
            name: 'counter.happy',
            init: 0,
            value: 10,
        },
        {
            name: 'counter.awards',
            init: 0,
            value: 3,
        },
    ]);

    const changeValues = () => {
        sleep(1000).then(() => {
            let tmpCounter = [...value];
            for(let it of tmpCounter){
                it.init = it.value;
            }
            setValue(tmpCounter);
        });
    }
    return ( 
        <section className="counter-area section-padding">
            <div className="container">
                <div className="counter-inner-box">
                <Waypoint onEnter={changeValues}>
                    <div className="row">
                        { value.map((v,i) => (
                            <div className="col-lg-3 col-md-6 col-sm-12 counter-item" key={`${v.name}_key`}>
                                <div className="single-counter-box">
                                    <div className="single-counter">
                                        <h3>
                                           +<Odometer className="odometer" value={v.init} format='d' duration={1000} />
                                        </h3>
                                        <p>{ t(v.name) }</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    </Waypoint>
                </div>
            </div>
        </section>
     );
}
 
export default withTranslation()(Counter);