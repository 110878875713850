import React,{ Fragment, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import PreLoader from './components/PreLoader';
import Main from './components/Main';
import { Translation } from 'react-i18next';

function App() {
  const [ loader, setLoader] = useState(true);

  const loaderCallback = useCallback((flag: boolean) => {
    setLoader(flag)
  },[]);
  
  return (
    <Fragment>
      {
        loader && (<PreLoader callback={loaderCallback} />)
      }
      <Translation ns="pardockTranslation">
        { (t) => (
          <Fragment>
            <Main />
            <Helmet>
              <title>{t('home.title')}</title>
            </Helmet>
          </Fragment>
        )}
      </Translation>
    </Fragment>
  );
}

export default App;
