import React, { useState } from 'react'
import { withTranslation } from 'react-i18next';
import TranslationProps from '../config/interfaces/TranslationProps';

const Services : React.FC<TranslationProps> = ({ t }: TranslationProps) => {
    const [ service ] = useState([
        { icon: 'pe-7s-display1', title: 'services.items.web', description: 'services.description.web' },
        { icon: 'pe-7s-rocket', title: 'services.items.agile', description: 'services.description.market' },
        { icon: 'pe-7s-tools', title: 'services.items.consult', description: 'services.description.ux' },
        { icon: 'pe-7s-server', title: 'services.items.data', description: 'services.description.communication' },
        { icon: 'pe-7s-user', title: 'services.items.soft', description: 'services.description.market' },
        { icon: 'pe-7s-users', title: 'services.items.team', description: 'services.description.ux' }
    ])
    return ( 
        <section id="service" className="service-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="section-title">
                            <h2>{ t('services.title') }</h2>
                        </div>
                    </div>
                </div>
                <div className="service-content">
                    <div className="row">
                        { service.map( (s, idx) => (
                            <div className="col-lg-4 col-md-6 col-sm-12 services-item" key={idx}>
                                <div className="single-services-box">
                                    <div className="single-services">
                                        <div className="services-icon"> <i className={`${s.icon}`}></i> </div>
                                        <div className="services-text">
                                            <h3>{t(s.title) }</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default withTranslation()(Services);
