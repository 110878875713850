import React from 'react'
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import animateScrollTo from 'animated-scroll-to';
import TranslationProps from '../config/interfaces/TranslationProps';

const Footer: React.FC<TranslationProps> = ({ t, i18n }: TranslationProps) => {
    const languageAction = (lang: string) => {
        if(lang){
            i18n.changeLanguage(lang);
            moment.locale(lang.substr(0, lang.indexOf('-')))
        }
    }

    const getElement = (id: string): (HTMLElement|null) => {
        return document.getElementById(id);
    }

    const changeLanguage = () => {
        const { language } = i18n;
        languageAction( language === 'en-US' ? 'es-MX' : 'en-US')
        const element = getElement('home')
        if (element !== null){
            animateScrollTo(element , { speed : 1000 });
        }
    }
    return ( 
        <footer className="footer-area">
            <div className="copyright-area text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p dangerouslySetInnerHTML={{ __html : t('footer.copy') || '' }}></p>
                            <p dangerouslySetInnerHTML={{ __html : t('footer.created')|| '' }}></p>
                            <p>
                                <button onClick={changeLanguage}>{ t('footer.language') }</button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
     );
}
 
export default withTranslation()(Footer);