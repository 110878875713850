import React from 'react'
import SkillItemProps from '../config/interfaces/SkillItemProps';

const SkillItem: React.FC<SkillItemProps> = ({ skill }: SkillItemProps) => {
    return ( 
        <div className="col-md-6 skill-box">	
            <div className="skill-item">
                <div className="skill-info clearfix">
                    <h4 className="float-left mb-3 mt-0">{skill.title}</h4>
                    <span className="float-right">{`${skill.value}%`}</span>
                </div>
                <div className="progress">
                    {   // @ts-ignore
                        <div className="progress-bar" role="progressbar" style={{ width: `${skill.width}%`}} {...{'aria-valuemin':"0", 'aria-valuemax': 100, 'aria-valuenow': skill.value }}>
                        </div>
                    }
                </div>
            </div>
        </div>
     );
}
 
export default SkillItem;